import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import VatEntity from "./VatEntity";

export default function Vat() {
    return <Breadcrumb
        title="MwSt"
        data={
            [
                {
                    href: '/vat',
                    name: 'MwSt'
                }
            ]
        }
    >
        <CRUD url="/vat" Component={VatEntity} add="/vat/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Mehrwertsteuer</Column>
                <Column sort="percentage">Prozentsatz</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.percentage}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/vat/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
