import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import ContractExtensionEntity from "./ContractExtensionEntity";

export default function ContractExtension() {
    return <Breadcrumb
        title="ContractExtension"
        data={
            [
                {
                    href: '/contract-extension',
                    name: 'Contract Extension'
                }
            ]
        }
    >
        <CRUD url="/contract-extension" Component={ContractExtensionEntity} add="/contract-extension/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <Column sort="nachname">Nachname</Column>
                <Column sort="email">Email</Column>
                <Column sort="phone">Phone</Column>
                <Column sort="created_at">Created at</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.email}</td>
                    <td>{row.phone}</td>
                    <td>{row.created_at}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/contract-extension/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
