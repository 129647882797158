import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";

export default function NetzEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/netz', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Netz Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Slug</Label>
                <Validator name="slug" type="required">
                    <Input
                        type="text"
                        value={data.slug}
                        onChange={a => setData({
                            slug: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Legal Name</Label>
                <Input
                    type="text"
                    value={data.legal_name}
                    onChange={a => setData({
                        legal_name: a.currentTarget.value
                    })}
                />
            </FormGroup>
            <FormGroup>
                <Label>Logo</Label>
                <SingleFilePicker
                    value={data.logo}
                    onChange={a => setData({ logo: a})}
                />
            </FormGroup>
            <FormGroup>
                <Label>Sim Logo</Label>
                <SingleFilePicker
                    value={data.sim_logo}
                    onChange={a => setData({ sim_logo: a})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}