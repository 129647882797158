
import React from 'react';
import {Actions, ActionsColumn, BootstrapDataTable,  Column, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {Route, Routes} from "react-router-dom";
import ProductGroupEntity from "./ProductGroupEntity";
import { ProductGroupToggle, VariantToggle } from '../../Components/Toggle';

const defaultParams = {
    sort: 'active',
    desc: true
};

const borderStyle = {
    borderLeft: 'solid 1px rgb(204, 204, 204)'
}

const borderStyleRight = {
    borderRight: 'solid 1px rgb(204, 204, 204)'
}

function ProductInfo({ row, style }) {
    if (!row)
        return <td className="text-center" style={{ ...borderStyle, ...borderStyleRight }} colSpan={5}>
            -
        </td>;

    return <>
        <td style={{ ...borderStyle, ...(style || {})}}>{ row.name }</td>
        <td>{ row.sell_price }</td>
        <td >{ row.buy_price }</td>
        <td className="text-center"><VariantToggle value={row} /></td>
        <td>{ row.main_product ? 'Yes' : 'No' }</td>
        <td className="text-center" style={borderStyleRight}>{ row.stock || 0 }</td>
    </>;
}


export default function ProductGroups() {
    return <Routes>
        <Route path="create" element={<ProductGroupEntity />} />
        <Route path=":id/edit" element={<ProductGroupEntity />} />
        <Route path="*" element={<Breadcrumb
            title="Products"
            data={
                [
                    {
                        href: '/product-type',
                        name: 'Products'
                    }
                ]
            }
        >
            <BootstrapDataTable noStrip url="/product" add="/product/create" defaultParams={defaultParams}>
                <thead>
                    <tr>
                        <th />
                        <th className="text-center" colSpan={4}>Group</th>
                        <th className="text-center" colSpan={5}>Variation</th>
                        <th />
                    </tr>
                    <tr>
                        <IdColumn />

                        <Column sort="active">Active</Column>
                        <Column sort="brand">Brand</Column>
                        <Column sort="category">Kategori</Column>
                        <Column sort="name">Name</Column>

                        <Column>Variant</Column>
                        <Column>Sell</Column>
                        <Column>Buy</Column>
                        <Column>Active</Column>
                        <Column>Main</Column>
                        <Column>Stock</Column>

                        <ActionsColumn />
                    </tr>
                </thead>
                <tbody>
                    {
                        row => <React.Fragment key={row.id}>
                            <tr>
                                <td className="min text-center" rowSpan={row.products.length || 1}>{row.id}</td>
                                <td className="text-center" rowSpan={row.products.length || 1}><ProductGroupToggle value={row} /></td>
                                <td rowSpan={row.products.length || 1}>{ row.brand.name }</td>
                                <td rowSpan={row.products.length || 1}>{ row.category.name }</td>
                                <td rowSpan={row.products.length || 1}>{row.name}</td>
                                <ProductInfo style={{ paddingLeft: '1.25rem' }} row={row.products[0]} />
                                <Actions
                                    edit={"/product/" + row.id + "/edit"}
                                    del={"/product/" + row.id}
                                    rowSpan={row.products.length || 1}
                                />
                            </tr>
                            {
                                row.products
                                    .filter((_,i) => i !== 0)
                                    .map((row, i) => <tr key={i}>
                                        <ProductInfo row={row} />
                                    </tr>)
                            }
                        </React.Fragment>
                    }
                </tbody>
            </BootstrapDataTable>
        </Breadcrumb>} />
    </Routes>;
}

function useReduce(arg0, arg1) {
    throw new Error('Function not implemented.');
}
