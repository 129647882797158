import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import MatchConfigEntity from "./MatchConfigEntity";

export default function MatchConfigs() {
	return <Breadcrumb
		title="Match Configurations"
		data={
			[
				{
					href: '/match-config',
					name: 'Match Configurations'
				}
			]
		}
	>
		<CRUD url="/match-config" Component={MatchConfigEntity} add="/match-config/create">
			<thead>
			<tr>
				<IdColumn />
				<Column sort="name">Name</Column>
				<Column sort="margin">Margin</Column>
				<Column sort="rounding">Rounding Type</Column>
				<ActionsColumn />
			</tr>
			</thead>
			<tbody>
			{
				row => <tr key={row.id}>
					<td className="min text-center">{row.id}</td>
					<td>{row.name}</td>
					<td>{row.margin || 0} &euro;</td>
					<td>{row.rounding}</td>
					<CRUDActions
						id={row.id}
						edit
						del={"/match-config/" + row.id}
					/>
				</tr>
			}
			</tbody>
		</CRUD>
	</Breadcrumb>;
}
