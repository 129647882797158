import React from 'react';
import {Header} from 'react-admin-base-front';
import {useApp, useAuth} from "react-admin-base";
import {MenuDesktop} from "./Components/Mobile";

export default function MenuHeader() {
    const app = useApp();

    return <Header>
        <MenuDesktop/>
    </Header>;
}
