import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import NetzEntity from "./NetzEntity";

export default function Netz() {
    return <Breadcrumb
        title="Netz"
        data={
            [
                {
                    href: '/netz',
                    name: 'Netz'
                }
            ]
        }
    >
        <CRUD url="/netz" Component={NetzEntity} add="/netz/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/netz/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
