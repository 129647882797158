import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import React from "react";
import {CheckBox, MultiFilePicker, Validator} from "react-admin-base-bootstrap";
import {VariantsEditor} from "../../Components/Variants";
import EditPropertyButton from "../../Components/EditPropertyButton";

export function ProductEditor({ value, onChange, rootAttributeIds, productType }) {
    if (!productType)
        return null;

    return <>
        <VariantsEditor
            value={value.variants}
            onChange={variants => onChange({ variants })}
            variants={productType.variants}
        />

        <EditPropertyButton
            product_type={productType}
            value={value.attributes || []}
            onChange={a => onChange({ ...value, attributes: a })}
            disabledAttributeIds={rootAttributeIds}
        />

        <div className="mt-3">
            <CheckBox
                type="checkbox"
                id="activep"
                label="Active"
                checked={value.active || false}
                onChange={a => onChange({ ...value, active: !value.active })}
            />
    
            <CheckBox
                type="checkbox"
                id="mainp"
                label="Main product"
                checked={value.main_product || false}
                onChange={a => onChange({ ...value, main_product: !value.main_product })}
            />
        </div>

        <Row>
            <Col>
                <FormGroup>
                    <Label>Buy Price</Label>
                    <Validator name="buy price" type="required|numeric">
                        <Input
                            type="number"
                            value={value.buy_price}
                            onChange={a => onChange({ ...value, buy_price: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>Sell Price</Label>
                    <Validator name="sell price" type="required|numeric">
                        <Input
                            type="number"
                            value={value.sell_price}
                            onChange={a => onChange({ ...value, sell_price: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>Original Price</Label>
                    <Validator name="original price" type="numeric">
                        <Input
                            type="number"
                            value={value.original_price}
                            onChange={a => onChange({ ...value, original_price: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>Stock</Label>
                    <Validator name="stock" type="numeric">
                        <Input
                            type="number"
                            value={value.stock}
                            onChange={a => onChange({ ...value, stock: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
        </Row>

        <FormGroup>
            <Label>Photos</Label>
            <MultiFilePicker
                accepts="image/*"
                value={value.photos}
                onChange={a => onChange({ ...value, photos: a })}
            />
        </FormGroup>

    </>;
}
