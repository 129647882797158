import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import BannerGroupEntity from "./BannerGroupEntity";

export default function Pages() {
    return <Breadcrumb
        title="Banner Group"
        data={
            [
                {
                    href: '/banner-group',
                    name: 'Banner Group'
                }
            ]
        }
    >
        <CRUD url="/banner-group" Component={BannerGroupEntity} add="/banner-group/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <Column>Sizes</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.width} x {row.height}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/banner-group/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
