import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {useCallback, useState} from 'react';
import {useAuth} from 'react-admin-base';
import {Button} from "reactstrap";
import IntegrationEntity from "./IntegrationEntity";

function SyncButton({ integrationId }) {
    const [ api ] = useAuth();
    const [ loading, setLoading ] = useState(false);

    const onSyncBtnClick = useCallback(async function(e) {
        e.preventDefault();

        setLoading(true);
        try {
            await api.tokenized.post("/integration/" + integrationId + "/sync");
        } catch(e) {

        } finally {
            setLoading(false);
        }
    }, [api.tokenized, integrationId, setLoading]);

    return <Button type="button" color="info" className="ms-1" outline onClick={onSyncBtnClick} disabled={loading}>
        <i className={"fas fa-sync" + (loading ? ' fa-spin' : '')} />{' '} Sync
    </Button>
}

export default function Integrations() {
    return <Breadcrumb
        title="Integrations"
        data={
            [
                {
                    href: '/integration',
                    name: 'Integrations'
                }
            ]
        }
    >
        <CRUD url="/integration" Component={IntegrationEntity} add="/integration/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/integration/" + row.id}
                    >
                        <SyncButton integrationId={row.id} />
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
