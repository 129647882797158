import {Navigate, Route, Routes} from 'react-router-dom';

import Dashboard from "./pages/Dash/Dashboard";
import Profile from "./pages/Profile/Edit";

import Users from "./pages/User/Users";
import Languages from "./pages/Language/Languages";
import Categories from "./pages/Category/Categories";
import ProductTypes from "./pages/ProductType/ProductTypes";
import ProductGroups from "./pages/ProductGroup/ProductGroups";
import Brands from "./pages/Brand/Brands";
import Pages from "./pages/Page/Pages";
import BannerGroups from "./pages/BannerGroup/BannerGroups";

import Posts from "./pages/Post/Posts";
import BlogCategory from "./pages/BlogCategory/BlogCategories";
import BlogTag from "./pages/BlogTag/BlogTags";

import Integrations from "./pages/Integration/Integrations";
import Orders from "./pages/Order/Orders";
import StatusGroups from "./pages/StatusGroup/StatusGroups";

import Availability from "./pages/Availability/Availability";
import VerificationMethod from "./pages/VerificationMethod/VerificationMethod";
import ShippingMethod from "./pages/ShippingMethod/ShippingMethod";
import TarifBonus from "./pages/TarifBonus/TarifBonus";
import Vat from "./pages/Vat/Vat";
import SeoRedirect from "./pages/SeoRedirect/SeoRedirect";
import Providers from "./pages/Provider/Providers";
import Tarifes from "./pages/Tarife/Tarifes";
import Netzs from './pages/Netz/Netzs';
import ContractExtension from "./pages/ContractExtension/ContractExtensions";
import Comment from "./pages/Comment/Comments";
import MatchConfigs from './pages/MatchConfig/MatchConfigs';

export default function Router() {
    return <Routes>
        <Route path="/dashboard" element={<Dashboard />}/>

        <Route path="/category/:pid/childs/*" element={<Categories />}/>
        <Route path="/category/*" element={<Categories />}/>

        <Route path="/product-type/*" element={<ProductTypes />}/>
        <Route path="/product/*" element={<ProductGroups />}/>
        <Route path="/brand/*" element={<Brands />}/>
        <Route path="/page/*" element={<Pages />}/>
        <Route path="/banner-group/*" element={<BannerGroups />}/>

        <Route path="/post/*" element={<Posts />}/>
        <Route path="/blog-category/*" element={<BlogCategory />}/>
        <Route path="/blog-tag/*" element={<BlogTag />}/>

        <Route path="/integration/*" element={<Integrations />}/>
        <Route path="/order/*" element={<Orders />}/>
        <Route path="/status-group/*" element={<StatusGroups />}/>

        <Route path="/availability/*" element={<Availability />}/>
        <Route path="/verification-method/*" element={<VerificationMethod />}/>
        <Route path="/shipping-method/*" element={<ShippingMethod />}/>
        <Route path="/tarifbonus/*" element={<TarifBonus />}/>
        <Route path="/vat/*" element={<Vat />}/>

        <Route path="/seo-redirect/*" element={<SeoRedirect />}/>
        <Route path="/match-config/*" element={<MatchConfigs />}/>

        <Route path="/language/*" element={<Languages />}/>

        <Route path="/provider/*" element={<Providers />}/>
        <Route path="/tarife/*" element={<Tarifes />}/>
        <Route path="/netz/*" element={<Netzs />}/>
        <Route path="/contract-extension/*" element={<ContractExtension />}/>
        <Route path="/comment/*" element={<Comment />}/>

        <Route path="/user/*" element={<Users />}/>
        <Route path="/profile" element={<Profile />}/>

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>;
}
