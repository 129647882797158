
import { useState } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { customCalculator } from '../../Components/Calculator';

export default function PriceTest({ matchConfig }) {
	const [ artikelPreis, setArtikelPreis ] = useState('0');
	const [ provision, setProvision ] = useState('0');
	const [ wkz, setWkz ] = useState('0');
	
	const valid = +artikelPreis > 0 && !isNaN(+wkz) && +provision > 0;
	
	const price = valid && (customCalculator(matchConfig))(artikelPreis, provision, wkz);
	
	return <Row>
		<Col md="6">
			<FormGroup>
				<Label>Artikel Preis</Label>
				<Input
					type="number"
					value={artikelPreis || ""}
					onChange={a => setArtikelPreis(a.currentTarget.value)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>Tarifprovision</Label>
				<Input
					type="number"
					value={provision || ""}
					onChange={a => setProvision(a.currentTarget.value)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>WKZ</Label>
				<Input
					type="number"
					value={wkz || ""}
					onChange={a => setWkz(a.currentTarget.value)}
				/>
			</FormGroup>
		</Col>
		{valid && <Col md="6" className="price-calculator align-self-center text-center justify-self-center">
			<div>
				<h3>Calculated price</h3>
				{(price >= 0 && <h2>{price.toFixed(2).replace('.', ',')}&euro;</h2>) || <h2>Error!</h2>}
			</div>
		</Col>}
	</Row>;
}