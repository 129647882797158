import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import PageEntity from "./PageEntity";

export default function Pages() {
    return <Breadcrumb
        title="Pages"
        data={
            [
                {
                    href: '/page',
                    name: 'Page'
                }
            ]
        }
    >
        <CRUD url="/page" Component={PageEntity} add="/page/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <Column sort="slug">Slug</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.title}</td>
                    <td>{row.slug}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/page/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
