import {Actions, ActionsColumn, Column, CRUD, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import StatusGroupEntity from "./StatusGroupEntity";
import React from "react";
import {MaskButtons, masks} from "./Masks";

const borderStyle = {
    borderLeft: 'solid 1px rgb(204, 204, 204)'
}

const borderStyleRight = {
    borderRight: 'solid 1px rgb(204, 204, 204)'
}

function StatusInfo({ row, style }) {
    if (!row)
        return <td className="text-center" style={{ ...borderStyle, ...borderStyleRight }} colSpan={6}>
            -
        </td>;

    return <>
        <td style={{ ...borderStyle, ...(style || {})}}>{ row.name }</td>
        <td>
            <MaskButtons
                prefix={row.id + "_"}
                value={(row && row.type) || 0}
                onChange={null}
                masks={masks}
                disabled
            />
        </td>
    </>;
}

export default function StatusGroups() {
    return <Breadcrumb
        title="Status Groups"
        data={
            [
                {
                    href: '/status-group',
                    name: 'Status Groups'
                }
            ]
        }
    >
        <CRUD url="/status-group" Component={StatusGroupEntity} add="/status-group/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name" className="text-center">Group</Column>
                <Column>Name</Column>
                <Column>Defaults</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <React.Fragment key={row.id}>
                    <tr key={row.id}>
                        <td className="min text-center" rowSpan={row.statuses.length || 1}>{row.id}</td>
                        <td className="text-center" rowSpan={row.statuses.length || 1} style={{ background: '#' + row.color.hex }}>{row.name}</td>
                        <StatusInfo row={row.statuses[0]} />
                        <Actions
                            rowSpan={row.statuses.length || 1}
                            edit={"/status-group/" + row.id + "/edit"}
                            del={"/status-group/" + row.id}
                        />
                    </tr>
                    {
                        row.statuses
                            .filter((_,i) => i !== 0)
                            .map((row, i) => <tr key={i}>
                                <StatusInfo row={row} />
                            </tr>)
                    }
                </React.Fragment>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
