import {Button, Card, CardBody, CardFooter, Nav, NavItem, NavLink} from 'reactstrap';
import React, {useCallback, useState} from "react";

export function CustomTabContainer({ children, onAdd }) {
    const [ index, setIndex ] = useState(0);

    const add = useCallback(function() {
        onAdd();
        setIndex(children.length);
    }, [children.length, onAdd]);

    return <div>
        <Nav tabs>
            { React.Children.map(children, (a, i) => <NavItem>
                <NavLink className={index === i ? "active" : ""} onClick={a => setIndex(i)}>
                    { a.props.title }
                </NavLink>
            </NavItem>) }
            { onAdd && <NavItem>
                <NavLink onClick={add}>
                    Add
                </NavLink>
            </NavItem> }
        </Nav>
        { children[index] }
    </div>;
}

export function CustomTab({ title, children, onDelete }) {
    return <Card>
        <CardBody>
            { children }
        </CardBody>
        { onDelete && <CardFooter className="text-right">
            <Button color="danger" type="button" outline onClick={onDelete}>
                <i className="fas fa-trash"  /> Delete
            </Button>
        </CardFooter> }
    </Card>;
}
