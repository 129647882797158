import React from 'react';
import {FormGroup, Input, Label, ModalBody, Row, Col} from "reactstrap";
import {useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import { FormattedMessage } from 'react-intl';
import PriceTest from './PriceTest';

export default function MatchConfigEntity({url, onReload, id}) {
	const entity = useEntity('/match-config', id, { rounding: 0 });
	const [data, setData] = entity;

	if (!data) {
		return null;
	}

	return <ModalEntityEditor size="lg" title="Match Configuration" url={url} onReload={onReload} entity={entity}>
		<ModalBody>
			<FormGroup>
				<Label>Name</Label>
				<Validator name="name" type="required">
					<Input
						type="text"
						value={data.name}
						onChange={a => setData({
							name: a.currentTarget.value
						})}
					/>
				</Validator>
			</FormGroup>
			<Row>
				<Col md="6">
					<FormGroup>
						<Label htmlFor="text-input"><FormattedMessage id="price.margin"/></Label>
						<Validator name="margin" type="numeric">
							<Input
								type="number"
								value={data.margin || ""}
								onChange={a => setData({margin: a.currentTarget.value})}
							/>
						</Validator>
					</FormGroup>
				</Col>
				<Col md="6">
					<FormGroup>
						<Label htmlFor="text-input">Rounding</Label>
						<Validator name="round" type="numeric">
							<Input
								type="select"
								value={(data.rounding || 0).toString()}
								onChange={a => setData({rounding: +a.currentTarget.value})}
							>
								<option value="0">No rounding</option>
								<option value="1">Round up (every 4,99&euro; / 9,99&euro;)</option>
								<option value="2">Round down (every 4,99&euro; / 9,99&euro;)</option>
							</Input>
						</Validator>
					</FormGroup>
				</Col>
			</Row>
			
			<FormGroup>
				<Label htmlFor="text-input">Minimaler Preis</Label>
				<Validator name="round" type="numeric">
					<Input
						type="number"
						value={data.min_price || ""}
						onChange={a => setData({min_price: a.currentTarget.value})}
					/>
				</Validator>
			</FormGroup>
			<hr />
			<h4 className="mb-5">Price Test</h4>
			<PriceTest
				matchConfig={data}
			/>
		</ModalBody>
	</ModalEntityEditor>;
}
