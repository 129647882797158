import React from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row, Table} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";

export default function BannerGroupEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/banner-group', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Banner Group Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <Table>
                <thead>
                    <tr>
                        <th className="min">#</th>
                        <th>Banner</th>
                        <th>URL</th>
                        <th>Date</th>
                        <th>Order</th>
                    </tr>
                </thead>
                <tbody>
                    <MultiValue value={data.banners} onChange={banners => setData({ banners })}>
                        { (row, onChange, index) => <tr key={index}>
                            <td>{ index + 1 }.</td>
                            <td>
                                <FormGroup>
                                    <Label>URL</Label>
                                    <Input
                                        type="text"
                                        className="text-center"
                                        value={(row && row.url) || ''}
                                        onChange={a => onChange({ ...(row || {}), url: a.currentTarget.value })}
                                        disabled={!row}
                                    />
                                </FormGroup>
                            </td>
                            <td>
                                <SingleFilePicker
                                    accepts="image/*"
                                    value={row && row.file}
                                    onChange={a => onChange(((a || (row && row.file_mobile)) && { ...(row || {}), file: a }) || null)}
                                />
                                <hr />
                                <SingleFilePicker
                                    accepts="image/*"
                                    value={row && row.file_mobile}
                                    onChange={a => onChange(((a || (row && row.file)) && { ...(row || {}), file_mobile: a }) || null)}
                                />
                            </td>
                            <td>
                                <FormGroup>
                                    <Label>Start date</Label>
                                    <Input
                                        type="datetime-local"
                                        className="text-center"
                                        value={(row && row.start_date) || ''}
                                        onChange={a => onChange({ ...(row || {}), start_date: a.currentTarget.value })}
                                        disabled={!row}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>End date</Label>
                                    <Input
                                        type="datetime-local"
                                        className="text-center"
                                        value={(row && row.end_date) || ''}
                                        onChange={a => onChange({ ...(row || {}), end_date: a.currentTarget.value })}
                                        disabled={!row}
                                    />
                                </FormGroup>
                            </td>
                            <td>
                                <FormGroup>
                                    <Input
                                        type="number"
                                        className="text-center"
                                        value={(row && row.order) || ''}
                                        onChange={a => onChange({ ...(row || {}), order: a.currentTarget.value })}
                                        disabled={!row}
                                    />
                                </FormGroup>
                            </td>
                        </tr> }
                    </MultiValue>
                </tbody>
            </Table>
        </ModalBody>
    </ModalEntityEditor>;
}