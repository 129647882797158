import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {CategoryTypeSelect} from "../../Components/Selects";
import SeoEntity from "../../Components/SeoEntity";

export default function CategoryEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/category', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Category Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <CheckBox
                type="checkbox"
                id="activep"
                label="Active"
                className="mb-3"
                checked={data.enabled || false}
                onChange={a => setData({ enabled: !data.enabled })}
            />
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Slug</Label>
                <Validator name="slug" type="required">
                    <Input
                        type="text"
                        value={data.slug}
                        onChange={a => setData({slug: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Type</Label>
                <Validator name="type" type="required">
                    <CategoryTypeSelect
                        value={data.type}
                        onChange={a => setData({type: a})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Order</Label>
                <Validator name="order" type="required|numeric">
                    <Input
                        type="text"
                        value={data.order}
                        onChange={a => setData({order: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <SeoEntity
                value={data.seo}
                onChange={seo => setData({ seo })}
            />
        </ModalBody>
    </ModalEntityEditor>;
}
