import React from 'react';
import {Button, Col, FormGroup, Input, Label, ModalBody, Row} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import Orderable from "../../Components/Orderable";
import ModalPortlet from "../../Components/ModalPortlet";

export default function ProductTypeEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/product_type', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Product Type Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <Attributes value={data.variants} onChange={variants => setData({ variants })} />
            <Orderable value={data.groups} onChange={groups => setData({ groups })}>
                {(group) => <div>
                    <Group
                        group={group}
                        onChange={a => setData({
                            groups: data.groups
                                .map(b => b === group ? a : b)
                        })}
                        onDelete={() => setData({
                            groups: data.groups.filter(a => a !== group) })}
                    />
                </div>}
            </Orderable>
            <Button
                type="button"
                color="primary"
                className="mb-3"
                onClick={a => setData({ groups: (data.groups || []).concat([{ }]) })}>Group Ekle</Button>
        </ModalBody>
    </ModalEntityEditor>;
}

class PropertyValue extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.value, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value || nextProps.hasColor !== this.props.hasColor;
    }

    render() {
        const { value, hasColor } = this.props;

        return <Row>
            <Col md="3" className="align-self-center">
                <Label htmlFor="text-input">Option {this.props.index}.</Label>
            </Col>
            <Col xs="12" md="7">
                <Input type="text" value={value.value || ""}
                       onChange={a => this.handleChange({ value: a.currentTarget.value })} />
            </Col>

            { hasColor && <Col xs="12" md="7">
                <Input type="text" placeholder="Color" value={(value.color && value.color.hex) || ""}
                       onChange={a => this.handleChange({ color: !a.currentTarget.value ? null : { hex: a.currentTarget.value } })} />
            </Col> }

            <Col xs="12" md="2">
                <Button color="danger" onClick={a => this.props.onDelete()}>Sil</Button>
            </Col>
        </Row>;
    }
}

function MainVariationButton({ value, onChange }) {
    return <Button type="button" color="primary" outline={!value} className="mb-3" onClick={a => onChange(!value)}>
        Main
    </Button>
}

class Property extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.property, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.property !== this.props.property;
    }

    render() {
        const { property } = this.props;

        return <div className="mb-2">
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Isim</Label>
                        <Input type="text"
                               value={property.name || ""}
                               onChange={a => this.handleChange({ name: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Tur</Label>
                        <Input type="select" value={property.type} onChange={a => this.handleChange({ type: +a.currentTarget.value })}>
                            <option value={1}>Ja/Nein</option>
                            <option value={2}>Text</option>
                            <option value={3}>Option</option>
                            <option value={5}>Color Option</option>
                            <option value={4}>Multi Select Option</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col className="col-auto align-self-end">
                    <CheckBox
                        label="Main"
                        checked={property.highlighted}
                        onChange={a => this.handleChange({ highlighted: !property.highlighted })}
                    />
                </Col>
            </Row>
            { (+property.type === 3 || +property.type === 4 || +property.type === 5) && <>
                <Orderable value={property.values} onChange={values => this.handleChange({ values })}>
                    {(value) => <div style={{padding: "5px 0 5px 25px"}}>
                        <PropertyValue
                            value={value}
                            hasColor={property.type === 5}
                            onChange={newValue => this.handleChange({ values: property.values
                                    .map(oldValue => oldValue === value ? newValue : oldValue) })}
                            onDelete={() => this.handleChange({ values: property.values.filter(a => a !== value) })}
                        />
                    </div>}
                </Orderable>

                <Button
                    className="ms-2"
                    color="primary"
                    onClick={a => this.handleChange({ values: (property.values || []).concat([{  }]) })}>Value Ekle</Button>
            </>}

            <Button color="danger" onClick={a => this.props.onDelete()}>Sil</Button>
        </div>;
    }
}

function Attributes({ value, onChange, isVariant }) {
    return <>
        <Orderable id="property" value={value} onChange={onChange}>
            {(property) => <div style={{padding: "5px 0 5px 25px"}}>
                <Property
                    key={property.id}
                    property={property}
                    onChange={newProperty => onChange(value
                            .map((oldProperty) => oldProperty === property ? newProperty : oldProperty))}
                    onDelete={() => onChange(value.filter(a => a !== property))}
                />
            </div>}
        </Orderable>
        <Button
            color="primary"
            onClick={a => onChange((value || []).concat([{ type: 1 }]))}>
            { isVariant ? 'Variant' : 'Property' } Ekle
        </Button>
    </>
}

export class Group extends React.Component {
    handleChange(model) {
        this.props.onChange({ ...this.props.group, ...model });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.group !== this.props.group;
    }

    render() {
        const { group } = this.props;

        return <div className="mb-2">
            <ModalPortlet onDelete={this.props.onDelete} title={<>
                <Col md="auto" className="align-self-center">
                    <Label className="mb-0"><b>Grup Adi</b></Label>
                </Col>
                <Col>
                    <Input type="text"
                           value={group.name || ""}
                           onChange={a => this.handleChange({ name: a.currentTarget.value })}
                    />
                </Col>
            </>}>
                <Attributes value={group.attributes} onChange={a => this.handleChange({attributes: a })} />
                <Button color="danger" onClick={a => this.props.onDelete()}>Group Sil</Button>
            </ModalPortlet>
        </div>;
    }
}