import React from "react";
import {CheckBox} from "react-admin-base-bootstrap";

export const masks = {
    1: 'Paid',
    2: 'Pending Sent',
    4: 'Sent',
    8: 'Error'
};

export function MaskButtons({ prefix, value, onChange, disabled, masks }) {
    return <>{Object.entries(masks)
        .map(([a, name],i) => <div className="mb-2" key={i}>
            <CheckBox
                disabled={!!disabled}
                id={"test_" + prefix + i}
                type="radio"
                checked={(value & +a) === +a}
                onClick={_ => onChange(value | (+a))}
                label={<>{name}</>}
            />
        </div>)}</>;
}

export function MaskTh({masks}) {
    return Object.values(masks)
        .map(a => <th key={a} className="text-center">
            { a }
        </th>);
}
