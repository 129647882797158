import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";

export default function TarifBonusEntity({url, onReload, id, parent_id}) {
    const entity = useEntity('/tarifbonus', id, {parent: parent_id && {id: parent_id}});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Tarif Rabatte" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <CheckBox
                type="checkbox"
                id="active"
                label="Active"
                className="mb-3"
                checked={data.enabled}
                onChange={a => setData({enabled: !data.enabled})}
            />
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
