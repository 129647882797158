import React, {useCallback, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {Link} from "react-router-dom";
import {useUser} from "./UserProvider";
import {useAuth} from 'react-admin-base';
import {LanguageSwitcher, useIsMobile} from "react-admin-base-bootstrap";

function UserPart() {
    const user = useUser();
    const [api] = useAuth();
    const [show, setShow] = useState(false);
    const isMobile = useIsMobile();

    const logout = useCallback(function () {
        api.logout();
    }, [api]);

    return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
        <DropdownToggle nav caret>
            { !isMobile && 'Merhaba,'}&nbsp;<b>{user.name ? user.name : user.email}</b>
        </DropdownToggle>
        <DropdownMenu right>

            { isMobile && <>
                <DropdownItem tag={Link} to="/">Portal</DropdownItem>
                <DropdownItem divider/>
            </> }

            {user.type !== 1 && <DropdownItem tag={Link} to="/profile">My Profile</DropdownItem>}
            <DropdownItem divider/>
            <DropdownItem onClick={logout}>Çıkış</DropdownItem>
        </DropdownMenu>
    </Dropdown>;
}


function AdminPart() {
    const [show, setShow] = useState(false);

    return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
        <DropdownToggle nav caret>
            Admin
        </DropdownToggle>
        <DropdownMenu left>
            <DropdownItem tag={Link} to="/user">Kullanıcılar</DropdownItem>
        </DropdownMenu>
    </Dropdown>;
}

export function MenuDesktop() {
    const isMobile = useIsMobile();

    if (!isMobile)
        return <>
            <Link className="text-muted" to="/">
                Dashboard
            </Link>

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align justify-content-end">
                    <LanguageSwitcher/>
                    <UserPart />
                </ul>
            </div>
        </>;
        
    return <div className="ms-auto">
        <UserPart />
    </div>;
}
