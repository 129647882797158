import React from "react";

import {Col, FormGroup, Label, Row} from 'reactstrap';
import {ValueSelect} from "./PropertySelector";

export function getVariantName(value) {
    return (value || [])
        .map(a => a.has ? a.attribute.name : a.value ? a.value.value : a.text)
        .join(", ") || 'New';
}

export function VariantEditor({ value, onChange, variant }) {
    return <FormGroup>
        <Label>{ variant.name }</Label>
        <ValueSelect
            attribute={variant}
            value={value || {}}
            onChange={a => onChange({ ...a })}
        />
    </FormGroup>;
}

export function VariantsEditor({ value, onChange, variants }) {
    return <Row>
        {
            variants
                .map(variant => <Col key={variant.id}>
                    <VariantEditor
                        variant={variant}
                        value={(value || []).find(b => b.attribute.id === variant.id)}
                        onChange={val => onChange(
                            (value || []).find(b => b.attribute.id === variant.id) ?
                                value.map(b => b.attribute.id === variant.id ? { ...val, attribute: variant } : b) :
                                (value || []).concat([{ ...val, attribute: variant }])
                        )}
                    />
                </Col>)
        }
    </Row>;
}