import React, {useCallback, useReducer, useState} from "react";
import PropertySelector from "./PropertySelector";
import {Button, Modal, ModalFooter, ModalHeader} from 'reactstrap';

export default function EditPropertyButton({ product_type, value, onChange, disabledAttributeIds  }) {
    const [ showing, toggle ] = useReducer(a => !a, false);

    const [ rValue, setRValue ] = useState(null);

    const onToggle = useCallback(function(e) {
        setRValue(value);
        toggle();
    }, [value, setRValue, toggle]);

    const onSave = useCallback(function(e) {
        onChange(rValue);
        toggle();
    }, [rValue, onChange, toggle]);

    return <>
        <Button type="button" size="lg" block outline color="info" onClick={onToggle}>Manage Properties</Button>

        { showing && <Modal size="lg"  isOpen toggle={onToggle}>
            <ModalHeader toggle={onToggle}>
                Properties
            </ModalHeader>
            <PropertySelector
                product_type={product_type}
                value={rValue}
                onChange={setRValue}
                disabledAttributeIds={disabledAttributeIds}
            />
            <ModalFooter>
                <Button type="button" block color="primary" onClick={onSave}>Save</Button>
                <Button type="button" block outline color="danger" onClick={onToggle}>Close</Button>
            </ModalFooter>
        </Modal> }
    </>
}
