import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import AvailabilityEntity from "./AvailabilityEntity";

export default function Availability() {
    return <Breadcrumb
        title="Lieferungsdauer"
        data={
            [
                {
                    href: '/availability',
                    name: 'Lieferungsdauer'
                }
            ]
        }
    >
        <CRUD url="/availability" Component={AvailabilityEntity} add="/availability/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Lieferzeit</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/availability/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
