import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import BlogTagEntity from "./BlogTagEntity";

export default function BlogTag() {
    return <Breadcrumb
        data={
            [
                {
                    href: '/blog-tag',
                    name: 'Blog Tag'
                }
            ]
        }
    >
        <CRUD url="/blog-tag" Component={BlogTagEntity} add="/blog-tag/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <Column sort="created_at">Created at</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.created_at}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/blog-tag/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
