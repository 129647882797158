import {ActionsColumn, Column, IdColumn, CRUD, CRUDActions} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import SeoRedirectEntity from "./SeoRedirectEntity";

export default function SeoRedirect() {
    return <Breadcrumb
        title="SEO-Redirect"
        data={
            [
                {
                    href: '/seo-redirect',
                    name: 'SEO-Redirect'
                }
            ]
        }
    >
        <CRUD url="/seo-redirect" Component={SeoRedirectEntity} add="/seo-redirect/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="from">from</Column>
                <Column sort="to">to</Column>
                <Column sort="code">code</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.redirectfrom}</td>
                    <td>{row.redirectto}</td>
                    <td>{row.type}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/seo-redirect/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
