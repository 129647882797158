import {Route, Routes} from "react-router-dom";
import PostEntity from "./PostEntity";
import {
    Actions,
    ActionsColumn,
    BootstrapDataTable,
    Column,
    IdColumn,
    Relative
} from "react-admin-base-bootstrap";
import {Breadcrumb} from 'react-admin-base-front';
import React from "react";
import {FormattedDate} from "react-intl";

export default function Posts() {
    return <Routes>
        <Route path="create" element={<PostEntity />} />
        <Route path=":id/edit" element={<PostEntity />} />
        <Route path="*" element={<Breadcrumb
            title="Posts"
            data={
                [
                    {
                        href: '/post',
                        name: 'Posts'
                    }
                ]
            }
        >
            <BootstrapDataTable noStrip url="/post" add="/post/create">
                <thead>
                <tr>
                    <IdColumn />

                    <Column>Featured</Column>
                    <Column sort="name">Title</Column>
                    <Column>Author</Column>

                    <Column>Categories</Column>
                    <Column>Tags</Column>
                    <Column>Enabled</Column>

                    <Column sort="published_at">Published at</Column>
                    <Column sort="created_at">Created at</Column>
                    <Column sort="updated_at">Updated at</Column>

                    <ActionsColumn />
                </tr>
                </thead>
                <tbody>
                {
                    row => <tr key={row.id}>
                        <td className="min text-center">{row.id}</td>
                        <td>
                            { row.featured_image && <Relative>
                                <img src={row.featured_image.access_url} alt={row.name} width='100px' />
                            </Relative> }
                        </td>
                        <td>
                            { row.name }
                        </td>
                        <td>
                            { row.author.name }
                        </td>

                        <td>{row.categories.map(a => a.name).join(", ")}</td>
                        <td>{row.tags.map(a => a.name).join(", ")}</td>

                        <td>{ row.enabled ? 'Yes' : 'No' }</td>

                        <td><FormattedDate value={row.published_at} /></td>
                        <td><FormattedDate value={row.created_at} /></td>
                        <td><FormattedDate value={row.updated_at} /></td>

                        <Actions
                            edit={"/post/" + row.id + "/edit"}
                            del={"/post/" + row.id}
                        />
                    </tr>
                }
                </tbody>
            </BootstrapDataTable>
        </Breadcrumb>} />
    </Routes>;
}
