import React, {useEffect, useMemo, useState} from 'react';
import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from "react-admin-base-bootstrap";
import {Breadcrumb} from 'react-admin-base-front';
import {Link, useParams} from "react-router-dom";
import {useAuth} from "react-admin-base";
import CategoryEntity from "./CategoryEntity";

export function useCategoryParents(id) {
    const [ parentCategories, setParentCategories ] = useState(null);
    const [ api ] = useAuth();

    useEffect(function() {
        async function update() {
            var parents = [];
            var cid = id;

            do {
                var category = await api.tokenized.get("/category/" + cid);
                parents.push(category.data);
                cid = category.data.parent_id;
            } while(category.data.parent);

            setParentCategories(parents.reverse());
        }

        if (!id) {
            setParentCategories([]);
        } else {
            update();
        }
    }, [api, id]);

    return parentCategories;
}

export default function Categories() {
    const { pid } = useParams();
    const params = useMemo(() => ({ parent_id: pid }), [pid]);

    const parentCategories = useCategoryParents(pid);

    if (!parentCategories)
        return null;

    return <Breadcrumb
        data={
            [
                {
                    href: '/category',
                    name: 'Categories'
                }
            ].concat(parentCategories.map(category => ({
                href: '/category/' + category.id + '/childs',
                name: category.name
            })))
        }
    >
        <CRUD Component={CategoryEntity} defaultParams={params} url={pid ? "/category/" + pid + "/childs" : "/category"} apiUrl="/category">
            <thead>
                <tr>
                    <IdColumn />
                    <Column sort="order" className="min">Order</Column>
                    <Column sort="name">Name</Column>
                    <ActionsColumn />
                </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="text-center">{row.order}</td>
                    <td><Link to={"/category/" + row.id + "/childs"}>{row.name}</Link></td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/category/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
