import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";

export default function ProviderEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/provider', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Provider Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Slug</Label>
                <Validator name="slug" type="required">
                    <Input
                        type="text"
                        value={data.slug}
                        onChange={a => setData({
                            slug: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>VVL Enabled</Label>
                <CheckBox
                    id="enable_vvl"
                    checked={data.vvl_enabled}
                    label="Enable VVL."
                    onChange={a => setData({
                        vvl_enabled: !data.vvl_enabled
                    })}
                />
            </FormGroup>
            <FormGroup>
                <Label>Order</Label>
                <Input
                    type="text"
                    value={data.order}
                    onChange={a => setData({
                        order: a.currentTarget.value
                    })}
                />
            </FormGroup>
            <FormGroup>
                <Label>Legal Name</Label>
                <Input
                    type="text"
                    value={data.legal_name}
                    onChange={a => setData({
                        legal_name: a.currentTarget.value
                    })}
                />
            </FormGroup>
            <FormGroup>
                <Label>Logo</Label>
                <SingleFilePicker
                    value={data.logo}
                    onChange={a => setData({ logo: a})}
                />
            </FormGroup>
            <FormGroup>
                <Label>Sim Logo</Label>
                <SingleFilePicker
                    value={data.sim_logo}
                    onChange={a => setData({ sim_logo: a})}
                />
            </FormGroup>
            <FormGroup>
                <Label>AGB URL</Label>
                <Validator name="agb_url" type="url">
                    <Input
                        type="text"
                        value={data.agb_url}
                        onChange={a => setData({
                            agb_url: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>AGB (PDF)</Label>
                <SingleFilePicker
                    value={data.agb}
                    onChange={a => setData({ agb: a})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}