import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, Validator} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";

export const roles = {
    '512': 'Admin'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, { type: 512 });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="Benutzer-Administration" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Vor- und Nachname</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label>E-Mail-Adresse</Label>
                <Validator name="email" type="required">
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label>Passwort</Label>
                <Input type="password" value={data.password}
                       autocomplete="new-password"
                       onChange={a => setData({password: a.currentTarget.value})}/>
            </FormGroup>
            <FormGroup>
                <Label>Status</Label>
                <Input
                    type="select"
                    value={data.type}
                    onChange={a => setData({type: +a.currentTarget.value, supplier:null})}
                >
                    { Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id} key={id}>{role}</option>)}
                </Input>
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
