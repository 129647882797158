import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {MapSelect} from "../../Components/Selects";

const integrationTypes = {
    1: {
        name: "Telefonvertrag",
        component({ value, onChange }) {
            return <>
                <FormGroup>
                    <Label>Endpoint</Label>
                    <Validator name="endpoint" type="required|url">
                        <Input
                            type="text"
                            value={(value && value.url) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                url: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
            </>;
        }
    },
    2: {
        name: "MobileCom",
        component({ value, onChange }) {
            return <>
                <FormGroup>
                    <Label>Username</Label>
                    <Validator name="username" type="required">
                        <Input
                            type="text"
                            value={(value && value.username) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                username: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Validator name="password" type="required">
                        <Input
                            type="text"
                            value={(value && value.password) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                password: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>FTP Username</Label>
                    <Validator name="ftpusername" type="required">
                        <Input
                            type="text"
                            value={(value && value.ftpusername) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                ftpusername: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Validator name="ftppassword" type="required">
                        <Input
                            type="text"
                            value={(value && value.ftppassword) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                ftppassword: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Client Id</Label>
                    <Validator name="client_id" type="required">
                        <Input
                            type="text"
                            value={(value && value.client_id) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                client_id: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Client Secret</Label>
                    <Validator name="client_secret" type="required">
                        <Input
                            type="text"
                            value={(value && value.client_secret) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                client_secret: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
            </>;
        }
    },
    4: {
        name: "Phone Plus",
        component({ value, onChange }) {
            return <>
                <FormGroup>
                    <Label>Endpoint</Label>
                    <Validator name="endpoint" type="required|url">
                        <Input
                            type="text"
                            value={(value && value.url) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                url: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>API Key</Label>
                    <Validator name="api_key" type="required">
                        <Input
                            type="text"
                            value={(value && value.api_key) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                api_key: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>ADPID</Label>
                    <Validator name="adpid" type="required">
                        <Input
                            type="text"
                            value={(value && value.adpid) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                adpid: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
            </>;
        }
    },
    5: {
        name: "Dirillish",
        component({ value, onChange }) {
            return <>
                <FormGroup>
                    <Label>Username</Label>
                    <Validator name="username" type="required">
                        <Input
                            type="text"
                            value={(value && value.username) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                username: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Validator name="password" type="required">
                        <Input
                            type="text"
                            value={(value && value.password) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                password: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Partner API Key</Label>
                    <Validator name="partnerApiKey" type="required">
                        <Input
                            type="text"
                            value={(value && value.partnerApiKey) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                partnerApiKey: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup>
                    <Label>Channel ID</Label>
                    <Validator name="channelId" type="required">
                        <Input
                            type="text"
                            value={(value && value.channelId) || ''}
                            onChange={a => onChange({
                                ...(value || {}),
                                channelId: a.currentTarget.value
                            })}
                        />
                    </Validator>
                </FormGroup>
            </>;
        }
    },
    '6': {
        name: 'Deinhandy',
        component() {
	    return <span>Nothing to show here.</span>;
	}
    }
};

export default function IntegrationEntity({ url, onReload, id }) {
    const entity = useEntity('/integration', id, { });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    const Component = data.type && integrationTypes[data.type].component;

    return <ModalEntityEditor size="lg" title="Integration Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Type</Label>
                <MapSelect
                    staticOptions={Object.entries(integrationTypes)
                        .reduce((a,[key, val]) => ({ ...a, [key]: val.name }), {})}
                    value={data.type}
                    onChange={type => setData({ type })}
                />
            </FormGroup>
            { Component && <Component
                value={data.options}
                onChange={options => setData({ options })}
            /> }
        </ModalBody>
    </ModalEntityEditor>;
}
