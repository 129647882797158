import {Actions, ActionsColumn, Column, CRUD, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import React from 'react';
import UserEntity, {roles} from "./UserEntity";

export default function Users() {
    return <Breadcrumb
        title="Benutzer"
        data={
            [
                {
                    href: '/user',
                    name: 'Benutzer'
                }
            ]
        }
    >
        <CRUD url="/user" Component={UserEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="name">Vor- und Nachname</Column>
                    <Column sort="email">E-Mail-Adresse</Column>
                    <Column sort="type">Status</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    <Actions
                        edit={"/user/" + row.id + '/edit'}
                        del={"/user/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
