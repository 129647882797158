import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import { TarifeToggle } from '../../Components/Toggle';
import TarifeEntity from "./TarifeEntity";

export default function Tarifes() {
    return <Breadcrumb
        title="Tarifes"
        data={
            [
                {
                    href: '/tarife',
                    name: 'Tarifes'
                }
            ]
        }
    >
        <CRUD url="/tarife" Component={TarifeEntity} add="/tarife/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="enabled">Enabled</Column>
                <Column sort="external_id">External ID</Column>
                <Column className="text-center">Netz</Column>
                <Column className="text-center">Provider</Column>
                <Column sort="name">Name</Column>
                <Column className="text-center">Fee</Column>
                <Column className="text-center">Setup Fee</Column>
                <Column className="text-center">Provision</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">
                        <TarifeToggle value={row} />
                    </td>
                    <td className="min text-center">{row.external_id}</td>
                    <td className="text-center">{(row.netz && row.netz.name) || '-'}</td>
                    <td className="text-center">{row.provider.name}</td>
                    <td>{row.name}</td>
                    <td className="text-center">{row.monthly_fee || 0} &euro;</td>
                    <td className="text-center">{row.setup_fee || 0} &euro;</td>
                    <td className="text-center">{row.provision || 0} &euro;</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/tarife/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
