import { useReducer } from "react";
import { CKEditor } from "react-admin-base-ckeditor";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function CKEditorModal({ value, onChange }) {
	const [ active, toggle ] = useReducer(a => !a, false);
	
	return <div>
		<Button type="button" color="primary" outline size="sm" className="mb-3" onClick={toggle}>
			<i className="fas fa-pencil-alt" />{' '}
			Edit
		</Button>
		<div dangerouslySetInnerHTML={{ __html: value || ''}} />
		{ active && <Modal size="lg" isOpen toggle={toggle}>
			<ModalHeader toggle={toggle}>
				HTML editor
			</ModalHeader>
			<CKEditor
				value={value}
				onChange={onChange}
			/>
			<ModalFooter>
				<Button block color="primary" type="button" onClick={toggle}>
					Close
				</Button>
			</ModalFooter>
		</Modal> }
	</div>;
}