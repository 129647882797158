import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import ShippingMethodEntity from "./ShippingMethodEntity";

export default function ShippingMethod() {
    return <Breadcrumb
        title="Versandart"
        data={
            [
                {
                    href: '/shipping-method',
                    name: 'Versandart'
                }
            ]
        }
    >
        <CRUD url="/shipping-method" Component={ShippingMethodEntity} add="/shipping-method/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Name</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/shipping-method/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
