import React, {useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

export default function ModalPortlet({ children, openDefault, title, onDelete }) {
    const [ open, setOpen ] = useState(openDefault || false);

    return <Card>
        <CardHeader>
            <Row className="align-items-center">
                {title}
                <Col md="auto">
                    <Button
                        outline
                        color="primary"
                        type="button"
                        onClick={() => setOpen(!open)}
                        className="ms-auto"
                    >
                        <i className="fa fa-pencil-alt"/>
                    </Button>
                </Col>
                { onDelete && <Col md="auto">
                    <Button
                        outline
                        color="danger"
                        type="button"
                        onClick={() => onDelete()}
                        className="ms-auto"
                    >
                        <i className="fa fa-trash"/>
                    </Button>
                </Col> }
            </Row>
        </CardHeader>
        { open && <Modal isOpen size="lg" toggle={() => setOpen(false)}>
            <ModalHeader toggle={() => setOpen(false)} className="header-row">
                <Row>
                    { title }
                </Row>
            </ModalHeader>
            <ModalBody>
                { children }
            </ModalBody>
            <ModalFooter>
                <Button block outline type="button" color="primary" onClick={() => setOpen(false)}>Kapat</Button>
            </ModalFooter>
        </Modal> }
    </Card>;
}
