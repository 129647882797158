import React from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row, Table} from "reactstrap";
import {useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {StatusGroupSelect, StatusSelect} from "../../Components/Selects";

function Address({ prefix, value, onChange }) {
    prefix = prefix || '';

    return <>
        <Row>
            <Col>
                <FormGroup>
                    <Label>Zip</Label>
                    <Validator name={prefix + "zip"} type="required">
                        <Input
                            type="text"
                            value={(value && value.zip) || ''}
                            onChange={a => onChange({ ...(value || {}), zip: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>City</Label>
                    <Validator name={prefix + "city"} type="required">
                        <Input
                            type="text"
                            value={(value && value.city) || ''}
                            onChange={a => onChange({ ...(value || {}), city: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup>
                    <Label>Street</Label>
                    <Validator name={prefix + "street"} type="required">
                        <Input
                            type="text"
                            value={(value && value.street) || ''}
                            onChange={a => onChange({ ...(value || {}), street: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>House Nr.</Label>
                    <Validator name={prefix + "house_no"} type="required">
                        <Input
                            type="text"
                            value={(value && value.house_no) || ''}
                            onChange={a => onChange({ ...(value || {}), house_no: a.currentTarget.value })}
                        />
                    </Validator>
                </FormGroup>
            </Col>
        </Row>
    </>;
}

function Firma({ value, onChange }) {
    return <>
        <CheckBox
            type="checkbox"
            id="firma_input"
            className="mb-3"
            label="Firma enabled"
            checked={!!value}
            onChange={a => onChange( value ? null : {} )}
        />
        { value && <>
        <FormGroup>
            <Label>Firma</Label>
            <Validator name="name" type="firma">
                <Input
                    type="text"
                    value={(value && value.name) || ''}
                    onChange={a => onChange({ ...(value || {}), name: a.currentTarget.value })}
                />
            </Validator>
        </FormGroup>
        <Address
            prefix="address"
            value={value && value.address}
            onChange={a => onChange({ ...(value || {}), address: a })}
        />
        </> }
    </>;
}

export function GetPaymentMethod(method) {
    switch (method) {
        case 1:
            return 'Card';
        case 2:
            return 'PayPal';
        case 3:
            return 'ConsorsFinanz'
        case 4:
            return 'Cash on Delivery';
        default:
    }

    return 'None';
}

export default function OrderEntity({ url, onReload, id }) {
    const entity = useEntity('/order', id, {  });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Order Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Status</Label>
                        <Validator name="status" type="required">
                            <StatusSelect
                                value={data.status}
                                onChange={a => setData({ status: a })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Status Group</Label>
                        <StatusGroupSelect
                            value={data.status && data.status.status_group}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Comment</Label>
                        <Input
                            type="textarea"
                            value={data.description}
                            onChange={a => setData({ description: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Internal Comment</Label>
                        <Input
                            type="textarea"
                            value={data.private_description}
                            onChange={a => setData({ private_description: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>First Name</Label>
                        <Validator name="first_name" type="required">
                            <Input
                                type="text"
                                value={data.first_name}
                                onChange={a => setData({
                                    first_name: a.currentTarget.value
                                })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Last Name</Label>
                        <Validator name="last_name" type="required">
                            <Input
                                type="text"
                                value={data.last_name}
                                onChange={a => setData({
                                    last_name: a.currentTarget.value,
                                })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label>Email</Label>
                <Validator name="email" type="required|email">
                    <Input
                        type="text"
                        value={data.email}
                        onChange={a => setData({
                            email: a.currentTarget.value,
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Phone</Label>
                <Validator name="phone" type="required">
                    <Input
                        type="text"
                        value={data.phone}
                        onChange={a => setData({
                            phone: a.currentTarget.value,
                        })}
                    />
                </Validator>
            </FormGroup>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Payment Method</Label>
                        <Input
                            type="text"
                            value={GetPaymentMethod(data.payment_method)}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Payment Reference</Label>
                        <Input
                            type="text"
                            value={data.payment_reference || ''}
                            disabled
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Address
                value={data.address}
                onChange={a => setData({ address: a })}
            />
            <Firma
                value={data.firma}
                onChange={a => setData({ firma: a })}
            />
            <Table striped bordered>
                <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th>Product</th>
                        <th>Variant</th>
                        <th className="text-center">Qty</th>
                        <th className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                { data.tarife && <tr>
                    <td className="text-center">1</td>
                    <td colSpan={2}>{data.tarife.provider.name} {data.tarife.name}</td>
                    <td className="text-center">1</td>
                    <td>
                        <b>Monthly price:</b> {data.tarife_monthly_fee}&euro;<br />
                        <b>Setup price:</b> {data.tarife_setup_fee}&euro;
                    </td>
                </tr> }
                { (data.items || [])
                    .map((a, i) => <tr key={a.product_id}>
                        <td className="text-center">{i+1+(data.tarife ? 1 : 0)}</td>
                        <td>{ a.product.product_group.name }</td>
                        <td>{ a.product.name }</td>
                        <td className="text-center">{ a.quantity }</td>
                        <td className="text-center">{ a.total } &euro;</td>
                    </tr>) }
                </tbody>
            </Table>
        </ModalBody>
    </ModalEntityEditor>;
}
