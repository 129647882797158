import React from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {useEntity, useFetch, useFreeFetch} from "react-admin-base";
import {LanguageSelect} from "../../Components/Selects";
import {SingleFilePicker, EntityEditor, Validator} from "react-admin-base-bootstrap";
import {Breadcrumb} from 'react-admin-base-front';
import { Navigate, useParams } from 'react-router-dom';

export default function LanguageEntity() {
    const { id } = useParams();
    const entity = useEntity('/language', id, { values: { } });
    const [data, setData] = entity;

    const [ keys ] = useFetch('/language/keys');
    const [ messages ] = useFreeFetch(data.parent && data.parent.precompiled_json && data.parent.precompiled_json.access_url);

    if (!data || !keys) {
        return null;
    }

    return <Breadcrumb
        data={
            [
                {
                    href: '/language',
                    name: 'Sprachen'
                },
                {
                    href: id ? '/language/' + data.id + '/edit' : '/language/create',
                    name: id ? 'Bearbeiten' : 'Erstellen'
                }
            ]
        }
    >
        { !id && data.id && <Navigate to={"/language/" + data.id + "/edit"} replace /> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label>Sprache</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.name || ''}
                                onChange={a => setData({name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label>Sprache</Label>
                                <Validator name="language_name" type="required">
                                    <Input
                                        type="text"
                                        value={data.language_name || ''}
                                        onChange={a => setData({language_name: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label>Sprachkode</Label>
                                <Validator name="language_code" type="required">
                                    <Input
                                        type="text"
                                        value={data.language_code || ''}
                                        onChange={a => setData({language_code: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Ikon</Label>
                        <SingleFilePicker
                            accepts="image/*"
                            value={data.icon}
                            onChange={a => setData({icon: a})}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Elternsprache </Label>
                        <LanguageSelect
                            filter={row => !id || (row.id !== +id)}
                            value={data.parent}
                            onChange={a => setData({parent: a})}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <b>Übersetzungsmanagement</b>
                </CardHeader>
                <CardBody>
                    <Row>
                        { keys.map(b => <Col md="6" key={b.key}>
                            <FormGroup>
                                <Label>{b.key}</Label>
                                <Input
                                    type="text"
                                    value={data.values[b.key] || ''}
                                    onChange={a => setData({ values: { ...data.values, [b.key]: a.currentTarget.value || null } })}
                                    placeholder={messages && messages[b.key]}
                                />
                                { !!b.description && <FormText color="muted">
                                    { b.description }
                                </FormText> }
                            </FormGroup>
                        </Col>) }
                    </Row>
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
}
