import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import OrderEntity, {GetPaymentMethod} from "./OrderEntity";
import {FormattedDate} from "react-intl";
import React from "react";

const defaultParams = {
    sort: 'id',
    desc: true
};

export default function Orders() {
    return <Breadcrumb
        title="Orders"
        data={
            [
                {
                    href: '/order',
                    name: 'Order'
                }
            ]
        }
    >
        <CRUD url="/order" Component={OrderEntity} noAdd defaultParams={defaultParams}>
            <thead>
            <tr>
                <IdColumn />
                <Column>Status</Column>
                <Column sort="first_name">Name</Column>
                <Column sort="email">Email</Column>
                <Column>Product(s)</Column>
                <Column className="text-center">Payment Method</Column>
                <Column className="text-center" sort="total">Total</Column>
                <Column className="text-center" sort="created_at">Created at</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="text-center">
                        <b style={{ color: '#' + row.status.status_group.color.hex }}>{row.status.name}</b>
                    </td>
                    <td>{row.first_name} {row.last_name}</td>
                    <td>{row.email}</td>
                    <td>
                        <ol className="product-list">
                            { row.tarife && <li>
                                { row.tarife.provider.name } { row.tarife.name }<br/>
                                <b>(Monthly: {row.tarife_monthly_fee}&euro;, Setup: {row.tarife_setup_fee}&euro;)</b>
                            </li> }
                            { row.items
                                .map(item => <li key={item.product_id}>
                                    { item.quantity }x { item.product.product_group.name } { item.product.name } (<b>{item.total}&euro;</b>)
                                </li>) }
                        </ol>
                    </td>
                    <td className="text-center">{ GetPaymentMethod(row.payment_method) }</td>
                    <td className="text-center">{ row.total } &euro;</td>
                    <td className="text-center"><FormattedDate value={row.created_at} /></td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/order/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
