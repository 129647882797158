import { useCallback, useReducer, useState } from "react";
import { useAuth } from "react-admin-base";
import { CheckBox } from "react-admin-base-bootstrap";

export function ProductGroupToggle(props) {
	return <Toggler
		activeKey="active"
		endpoint="/product"
		{...props}
	/>;
}

export function VariantToggle(props) {
	return <Toggler
		activeKey="active"
		endpoint="/variant"
		{...props}
	/>;
}

export function TarifeToggle(props) {
	return <Toggler
		activeKey="enabled"
		endpoint="/tarife"
		{...props}
	/>;
}

export default function Toggler({ value, activeKey, endpoint }) {
	const [ api ] = useAuth();
	const [ loading, setLoading ] = useState(false);
	const [,forceReload] = useReducer(a => ++a, 0);
	
	const onClick = useCallback(async function (e) {
		e.preventDefault();
		
		setLoading(true);
		try {
			const prevActive = value[activeKey];
			const response = await api.tokenized.delete(endpoint + '/' + value.id + (prevActive ? '/disable' : '/enable'));
			value[activeKey] = !prevActive;
			forceReload();
		} finally {
			setLoading(false);
		}
	}, [value[activeKey], forceReload, endpoint, api.tokenized, value.id, setLoading]);
	
	return <CheckBox
		id={"pg_" + value.id}
		checked={value[activeKey]}
		onClick={onClick}
		disabled={loading}
	/>;
}