import React from 'react';
import {Card, CardBody, Col, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import { EntityEditor, Validator} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';

export default function Edit() {
    const entity = useEntity('/user', 'me');
    const [data, setData] = entity;

    return <>
        <Breadcrumb
            title="Mein Profil "
            data={
                [
                    {
                        href: '/profil',
                        name: 'Mein Profil '
                    }
                ]
            }
        />
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">Vor- und Nachname</Label>
                        </Col>
                        <Col md="4">
                            <Validator name="name" type="required">
                                <Input
                                    type="text"
                                    value={data.name}
                                    onChange={a => setData({name: a.currentTarget.value})}
                                />
                            </Validator>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">E-Mail-Adresse</Label>
                        </Col>
                        <Col md="10">
                            <Validator name="email" type="required">
                                <Input type="text" value={data.email}
                                       onChange={a => setData({email: a.currentTarget.value})}/>
                            </Validator>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2">
                            <Label htmlFor="text-input">Passwort</Label>
                        </Col>
                        <Col md="10">
                            <Input
                                type="password"
                                value={data.password}
                                autocomplete="new-password"
                                onChange={a => setData({password: a.currentTarget.value})}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </EntityEditor>
    </>;
}
