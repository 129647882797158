import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {CKEditor} from "react-admin-base-ckeditor";

export default function SeoEntity({ value, onChange }) {
    return <>
        <FormGroup>
            <Label>Title</Label>
            <Input
                type="text"
                value={(value && value.title) || ''}
                onChange={a => onChange({ ...(value || {}), title: a.currentTarget.value })}
            />
        </FormGroup>
        <FormGroup>
            <Label>Keywords</Label>
            <Input
                type="text"
                value={(value && value.keywords) || ''}
                onChange={a => onChange({ ...(value || {}), keywords: a.currentTarget.value })}
            />
        </FormGroup>
        <FormGroup>
            <Label>Description</Label>
            <Input
                type="textarea"
                value={(value && value.description) || ''}
                onChange={a => onChange({ ...(value || {}), description: a.currentTarget.value })}
                rows={3}
            />
        </FormGroup>
        <Row>
            <Col md="6">
                <FormGroup>
                    <Label>Left Title</Label>
                    <Input
                        type="text"
                        value={(value && value.main_title) || ''}
                        onChange={a => onChange({ ...(value || {}), main_title: a.currentTarget.value })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Left Content</Label>
                    <CKEditor
                        value={(value && value.main_content) || ''}
                        onChange={a => onChange({ ...(value || {}), main_content: a })}
                    />
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label>Right Title</Label>
                    <Input
                        type="text"
                        value={(value && value.secondary_title) || ''}
                        onChange={a => onChange({ ...(value || {}), secondary_title: a.currentTarget.value })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Right Content</Label>
                    <CKEditor
                        value={(value && value.secondary_content) || ''}
                        onChange={a => onChange({ ...(value || {}), secondary_content: a })}
                    />
                </FormGroup>
            </Col>
        </Row>
    </>;
}