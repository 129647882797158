import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";

export default function BrandEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/brand', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Brand Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Logo</Label>
                <Validator name="logo">
                    <SingleFilePicker
                        value={data.logo}
                        onChange={a => setData({ logo: a})}
                    />
                </Validator>
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}