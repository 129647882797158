
import { Validator } from 'react-admin-base-bootstrap';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import EditPropertyButton from './EditPropertyButton';
import { ProductTypeSelect } from './Selects';

export default function ProductTypeWithProperties({ data, setData }) {
	return <Row>
		<Col>
			<FormGroup>
				<Label>Product Type</Label>
				<Validator name="product_type" type="required">
					<ProductTypeSelect
						value={data.product_type}
						onChange={a => setData({ product_type: a })}
					/>
				</Validator>
			</FormGroup>
		</Col>
		{ data.product_type && <Col className="col-auto align-self-center">
			<FormGroup>
				<Label>Properties</Label>
				<EditPropertyButton
					product_type={data.product_type}
					value={data.attributes || []}
					onChange={a => setData({ attributes: a })}
					disabledAttributeIds={[]}
				/>
			</FormGroup>
		</Col> }
	</Row>;
}
