import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import VerificationMethodEntity from "./VerificationMethodEntity";

export default function VerificationMethod() {
    return <Breadcrumb
        title="Versandkosten"
        data={
            [
                {
                    href: '/verification-method',
                    name: 'Versandkosten'
                }
            ]
        }
    >
        <CRUD url="/verification-method" Component={VerificationMethodEntity} add="/verification-method/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Versandkosten</Column>
                <Column sort="preis">Preis</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.price}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/verification-method/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
