import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import ProviderEntity from "./ProviderEntity";

export default function Providers() {
    return <Breadcrumb
        title="Providers"
        data={
            [
                {
                    href: '/provider',
                    name: 'Providers'
                }
            ]
        }
    >
        <CRUD url="/provider" Component={ProviderEntity} add="/provider/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="order" className="min">Order</Column>
                <Column sort="name">Name</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.order}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/provider/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
