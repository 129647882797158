import React from 'react';
import {Breadcrumb} from 'react-admin-base-front';
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Row} from 'reactstrap';

export default function Dashboard() {
    return <Breadcrumb
        title="Werkzeuge"
        data={
            [
                {
                    href: '/dashboard',
                    name: 'Werkzeuge'
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <Row>
                    <Col md="12">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/order"} color="info">
                                <i className="fas fa-language"></i>{" "}
                                Orders
                            </Link>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/user"} color="info">
                                <i className="fa fa-users"></i>{" "}
                                Benutzer
                            </Link>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/user"} color="info">
                                <i className="fa fa-user"></i>{" "}
                                Profil bearbeiten
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    </Breadcrumb>;
}
