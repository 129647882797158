import React from 'react';
import {FormText} from 'reactstrap';

function strip_html(text) {
    return text.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
}

function matcher(html, regex) {
    const match = html.match(regex);
    return (match && match.length) || 0;
}

export const WordCounter = React.memo(function({ focusWord, value }) {
    if (!value)
        return null;

    const html = value || '';

    const link_count = matcher(html, /<a(.*?)<\/a>/gmis);
    const image_count = matcher(html, /<img(.*?)>/gmis);
    const h2_count = matcher(html, /<h2>(.*?)<\/h2>/gmis);
    const h3_count = matcher(html, /<h3>(.*?)<\/h3>/gmis);

    const text = strip_html(html);

    const match = focusWord && text
        .match(new RegExp( focusWord, "gi" ));

    const count = (match && match.length) || 0;

    return <FormText color="muted">
        { !!focusWord && <><b>{ focusWord }:</b> { count }<br /></> }
        <b>Wortzahl:</b> { text.split(' ').length }<br />
        { link_count > 0 && <><b>Linkanzahl:</b> { link_count }<br /></> }
        { image_count > 0 && <><b>Bildanzahl:</b> { image_count }<br /></> }
        { h2_count > 0 && <><b>H2 Anzahl:</b> { h2_count }<br /></> }
        { h3_count > 0 && <><b>H3 Anzahl:</b> { h3_count }<br /></> }
    </FormText>;
});
