import React from 'react';
import {Menu, MenuGroup, Sidebar} from 'react-admin-base-front';
import {FormattedMessage} from "react-intl";

export default function MenuSidebar() {
    return <Sidebar>
        
        <Menu title={<FormattedMessage id="ORDERS"/>} to="/order" icon="fas fa-tags" />
        <Menu title={<FormattedMessage id="CONTRACT_EXTENSION"/>} to="/contract-extension" icon="fas fa-store"/>

        <MenuGroup title={<FormattedMessage id="PRODUKTE"/>}>
            <Menu title={<FormattedMessage id="PRODUKT"/>} to="/product" icon="fas fa-mobile-alt"/>
            <Menu title={<FormattedMessage id="TARIFE"/>} to="/tarife" icon="fas fa-sim-card"/>
            <Menu title={<FormattedMessage id="TARIFE_RABATT"/>} to="/tarifbonus" icon="fas fa-percent"/>
            <Menu title={<FormattedMessage id="OPTIONEN"/>} to="/product-type" icon="fas fa-compress-arrows-alt"/>
            <Menu title={<FormattedMessage id="HERSTELLER"/>} to="/brand" icon="fas fa-industry"/>
        </MenuGroup>

        <MenuGroup title={<FormattedMessage id="FRONT"/>}>
            <Menu title={<FormattedMessage id="POSTS"/>} to="/post" icon="fas fa-rss-square"/>
            <Menu title={<FormattedMessage id="BANNERS"/>} to="/banner-group" icon="fas fa-images"/>
            {<Menu title={<FormattedMessage id="COMMENTS"/>} to="/comment" icon="fas fa-comment"/>}
            <Menu title={<FormattedMessage id="SEITEN"/>} to="/page" icon="fas fa-file-alt"/>
            {<Menu title={<FormattedMessage id="BLOG_CATEGORY"/>} to="/blog-category" icon="fas fa-caret-right"/>}
            {<Menu title={<FormattedMessage id="BLOG_TAG"/>} to="/blog-tag" icon="fas fa-caret-right"/>}
            {/*<Menu title="Prämien-Slides" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Top Handy Angebote" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Header & Footer" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Deals" to="/" icon="fas fa-caret-right"/>*/}
        </MenuGroup>

        <MenuGroup title={<FormattedMessage id="MASTER"/>}>
            <Menu title={<FormattedMessage id="PROVIDER"/>} to="/provider" icon="fas fa-building"/>
            <Menu title={<FormattedMessage id="NETZ"/>} to="/netz" icon="fas fa-wifi"/>
            <Menu title={<FormattedMessage id="STATUS"/>} to="/status-group" icon="fas fa-tags"/>
            <Menu title={<FormattedMessage id="KATEGORIEN"/>} to="/category" icon="fas fa-list"/>
            {/*<Menu title="Seo Einstellungen" to="/seo" icon="fas fa-caret-right"/>*/}
            <Menu title={<FormattedMessage id="SEO_REDIRECT"/>} to="/seo-redirect" icon="fas fa-random"/>
            {/*<Menu title={<FormattedMessage id="MWST"/>} to="/vat" icon="fas fa-caret-right"/>*/}
            {/*<Menu title={<FormattedMessage id="VERSANDART"/>} to="/shipping-method" icon="fas fa-caret-right"/>*/}
            {/*<Menu title={<FormattedMessage id="VERSANDKOSTEN"/>} to="/verification-method" icon="fas fa-caret-right"/>*/}
            {/*<Menu title={<FormattedMessage id="LIEFERUNGSDAUER"/>} to="/availability" icon="fas fa-caret-right"/>*/}
            <Menu title={<FormattedMessage id="PRICE_CONFIG"/>} to="/match-config" icon="fas fa-caret-right"/>
            <Menu title={<FormattedMessage id="INTEGRATIONS"/>} to="/integration" icon="fas fa-sync"/>
            {/*<Menu title="Delivery and shipping" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Shipping Costs" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="SEO Settings" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="E-mail" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Area code Number Port" to="/" icon="fas fa-caret-right"/>*/}
            {/*<Menu title="Provider Number Port" to="/" icon="fas fa-caret-right"/>*/}
        </MenuGroup>

        <MenuGroup title={<FormattedMessage id="OTHER"/>}>
            {/*<Menu title={<FormattedMessage id="STARTSEITE"/>} to="/dashboard/index" icon="fas fa-tachometer-alt"/>*/}
            {/*<Menu title="Sprachfunktionen im Frontend" to="/language" icon="fas fa-language"/>*/}
            <Menu title={<FormattedMessage id="USERS"/>} to="/user" icon="fas fa-users"/>
            <Menu title={<FormattedMessage id="MY_PROFILE"/>} to="/profile" icon="fas fa-user"/>
        </MenuGroup>
    </Sidebar>;
}
