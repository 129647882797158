import React from 'react';
import {FormGroup, Input, Label, Row, Col, ModalBody} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import { IntegrationSelect, MatchConfigSelect, NetzSelect, ProviderSelect, TarifBonusSelect } from '../../Components/Selects';
import ProductTypeWithProperties from '../../Components/ProductTypeWithProperties';
import CategorySelect from '../../Components/CategorySelect';

export default function TarifeEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/tarife', id, {  });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Tarife Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <CheckBox
                    id="enabled"
                    checked={!!data.enabled}
                    onClick={a => setData({ enabled: !data.enabled })}
                    label="Enabled"
                />
            </FormGroup>
            
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Provider</Label>
                        <Validator name="provider" type="required">
                            <ProviderSelect
                                value={data.provider}
                                onChange={a => setData({ provider: a })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Netz</Label>
                        <Validator name="netz" type="required">
                            <NetzSelect
                                value={data.netz}
                                onChange={a => setData({ netz: a })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Match Configuration</Label>
                        <MatchConfigSelect
                            value={data.match_configs}
                            onChange={a => setData({ match_configs: a })}
                            isMulti
                        />
                    </FormGroup>
                </Col>
            </Row>
            
            <ProductTypeWithProperties
                data={data}
                setData={setData}
            />
            
            <FormGroup>
                <Label>Name</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({
                            name: a.currentTarget.value,
                            slug: makeSlug(data.name || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Slug</Label>
                <Validator name="slug" type="required">
                    <Input
                        type="text"
                        value={data.slug}
                        onChange={a => setData({
                            slug: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Integration</Label>
                        <IntegrationSelect
                            value={data.integration}
                            onChange={a => setData({ integration: a })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Integration ID</Label>
                        <Input
                            type="text"
                            value={data.external_id}
                            onChange={a => setData({ external_id: a.currentTarget.value })}
                            disabled={!data.integration}
                        />
                    </FormGroup>
                </Col>
            </Row>
            
            <FormGroup>
                <Label>Category</Label>
                <Validator name="category" type="required">
                    <CategorySelect
                        value={data.category && data.category.id}
                        onChange={a => setData({ category: (a && { id: a }) || null })}
                    />
                </Validator>
            </FormGroup>
            
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Monthly fee</Label>
                        <Validator name="monthly_fee" type="required|numeric">
                            <Input
                                type="text"
                                value={data.monthly_fee}
                                onChange={a => setData({ monthly_fee: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Setup fee</Label>
                        <Input
                            type="text"
                            value={data.setup_fee}
                            onChange={a => setData({ setup_fee: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Special Monthly fee</Label>
                        <Validator name="special_monthly_price" type="numeric">
                            <Input
                                type="text"
                                value={data.special_monthly_price}
                                onChange={a => setData({ special_monthly_price: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Special Monthly fee (until months)</Label>
                        <Validator name="special_monthly_price_months" type="numeric">
                            <Input
                                type="text"
                                value={data.special_monthly_price_months}
                                onChange={a => setData({ special_monthly_price_months: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Provision</Label>
                        <Input
                            type="text"
                            value={data.provision}
                            onChange={a => setData({ provision: a.currentTarget.value })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Contract Duration</Label>
                        <Validator name="contract_duration" type="numeric">
                            <Input
                                type="text"
                                value={data.contract_duration}
                                onChange={a => setData({ contract_duration: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            
            <FormGroup>
                <Label>Tarifbonus</Label>
                <TarifBonusSelect
                    value={data.tarifbonus}
                    onChange={a => setData({ tarifbonus: a })}
                />
            </FormGroup>
            
            <FormGroup>
                <Label>Tarifinformation PDF</Label>
                <SingleFilePicker
                    value={data.information_pdf}
                    onChange={a => setData({ information_pdf: a})}
                />
            </FormGroup>
            
            <FormGroup>
                <Label>Fussnotes</Label>
                <Input
                    type="textarea"
                    rows={4}
                    value={data.fussnote}
                    onChange={a => setData({ fussnote: a.currentTarget.value })}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}