import React from 'react';
import { FormGroup, Input, Label, ModalBody} from "reactstrap";
import { useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import {FormattedMessage} from "react-intl";
import { ProviderSelect} from "../../Components/Selects";
import {CKEditor} from "react-admin-base-ckeditor";

export default function ContractExtensionEntity({url, onReload, id, parent_id}) {
    const entity = useEntity('/contract-extension', id, {parent: parent_id && {id: parent_id}});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Contract Extension Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Provider</Label>
                <Validator name="provider" type="required">
                    <ProviderSelect
                        value={data.provider}
                        onChange={a => setData({provider: a})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input">
                    <FormattedMessage id="FIRST_NAME"/>
                </Label>
                <Validator
                    name="first_name"
                    type="required"
                >
                    <Input type="text"
                           value={data.first_name}
                           onChange={a => setData({first_name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input">
                    <FormattedMessage id="LAST_NAME"/></Label>
                <Validator
                    name="last_name"
                    type="required"
                >
                    <Input type="text"
                           value={data.last_name}
                           onChange={a => setData({last_name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>


            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="USER_MAIL"/></Label>
                <Validator
                    name="email"
                    type="required"
                >
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="text-input"> <FormattedMessage id="USER_PHONE"/></Label>
                <Validator
                    name="phone"
                    type="required"
                >
                    <Input type="text" value={data.phone}
                           onChange={a => setData({phone: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input">Date</Label>
                <Input type="date" value={data.date}
                       onChange={a => setData({date: a.currentTarget.value})}/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="MESSAGE"/></Label>
                <CKEditor
                    value={data.message}
                    onChange={a => setData({message: a})}
                />
            </FormGroup>
            <FormGroup>
                <Label> <FormattedMessage id="LOG_FILE"/></Label>
                <SingleFilePicker
                    value={data.log_file}
                    onChange={a => setData({log_file: a})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}