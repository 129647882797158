import './App.scss';

import {App, Authorized, AuthProvider, ForgotPasswordRoute, LoginRoute, NotAuthorized} from 'react-admin-base';
import {LanguageProvider} from 'react-admin-base-bootstrap';
import {Login, MainLayout, Reset} from 'react-admin-base-front';

import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./Components/UserProvider";
import UploadConfig from "./UploadConfig";
import languageDe from './i18n/en.json';
import languageEn from './i18n/en.json';
import languageTr from './i18n/en.json';
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

Sentry.init({
    dsn: "https://e7c8f72f68ee421e98ab07799701caf3@o1140980.ingest.sentry.io/6198766",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});


const languages = {
    de: {
        icon: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/de.svg',
        name: 'German',
        messages: languageDe
    },
    en: {
        icon: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/us.svg',
        name: 'English',
        messages: languageEn
    },
    tr: {
        icon: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/tr.svg',
        name: 'Turkish',
        messages: languageTr
    }
};

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id={process.env.REACT_APP_ID}
                name={process.env.REACT_APP_NAME}
                endpoint={process.env.REACT_APP_ENDPOINT}
                logo={"/" + process.env.REACT_APP_ID + '/logo-2.png'}
                onlylogo={"/" + process.env.REACT_APP_ID + '/logo.png'}
            >
                <LanguageProvider defaultLanguage="en" languages={languages}>
                    <AuthProvider
                        tokenEndpoint="/token"
                        client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                        client_secret="123456"
                    >
                        <NotAuthorized>
                            <LoginRoute>
                                <Login/>
                            </LoginRoute>
                            <ForgotPasswordRoute>
                                <Reset/>
                            </ForgotPasswordRoute>
                        </NotAuthorized>
                        <Authorized>
                            <UserProvider>
                                <UploadConfig>
                                    <MainLayout>
                                        <MenuHeader/>
                                        <MenuSidebar/>
                                        <Router/>
                                        <Footer/>
                                    </MainLayout>
                                </UploadConfig>
                            </UserProvider>
                        </Authorized>
                    </AuthProvider>
                </LanguageProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
