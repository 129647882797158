import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";

export default function SeoRedirectEntity({url, onReload, id, parent_id}) {
    const entity = useEntity('/seo-redirect', id, { type: 301 });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Seo Redirect" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <CheckBox
                type="checkbox"
                id="active"
                label="Active"
                className="mb-3"
                checked={data.enabled}
                onChange={a => setData({enabled: !data.enabled})}
            />
            <FormGroup>
                <Label>Redirect From</Label>
                <Validator name="from" type="required">
                    <Input
                        type="text"
                        value={data.redirectfrom}
                        onChange={a => setData({
                            redirectfrom: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Redirect To </Label>
                <Validator name="to" type="required">
                    <Input
                        type="text"
                        value={data.redirectto}
                        onChange={a => setData({
                            redirectto: a.currentTarget.value
                        })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label>Art</Label>
                <Input
                    type="select"
                    value={data.type || "301"}
                    onChange={a => setData({ type: +a.currentTarget.value })}
                >
                    <option value="301">PERMANENT</option>
                    <option value="302">TEMPORARY</option>
                </Input>
            </FormGroup>

        </ModalBody>
    </ModalEntityEditor>;
}
