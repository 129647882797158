import React, {useMemo} from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {makeSlug, useEntity, useFetch} from "react-admin-base";

import { CheckBox, EntityEditor, Validator} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import CategorySelect from "../../Components/CategorySelect";
import {BrandSelect, MatchConfigSelect, ProductTypeSelect} from "../../Components/Selects";
import {CustomTab, CustomTabContainer} from "../../Components/CustomTabs";
import {ProductEditor} from "../Product/ProductEntity";
import {getVariantName} from "../../Components/Variants";
import EditPropertyButton from "../../Components/EditPropertyButton";
import { Navigate, useParams } from 'react-router-dom';

export default function ProductGroupEntity() {
    const { id } = useParams();
    const entity = useEntity('/product', id, { values: { } });
    const [data, setData] = entity;

    const [ realProductType ] = useFetch(data.product_type && ('/product_type/' + data.product_type.id));

    const rootAttributeIds = useMemo(() => (data.attributes || []).map(a => +a.attribute.id), [data.attributes]);
    const childAttributeIds = useMemo(() => (data.products || [])
        .reduce((a, b) => a.concat(
            (b.attributes || [])
                .map(a => +a.attribute.id)
                .filter(c => a.indexOf(c) === -1)
        ), []), [data.products]);

    return <Breadcrumb
        title={id ? 'Edit' : 'Create'}
        data={
            [
                {
                    href: '/product',
                    name: 'Product'
                },
                {
                    href: id ? '/product/' + data.id + '/edit' : '/product/create',
                    name: id ? 'Edit' : 'Create'
                }
            ]
        }
    >
        { !id && data.id && <Navigate to={"/product/" + data.id + "/edit"} replace /> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <CheckBox
                        type="checkbox"
                        id="active"
                        label="Active"
                        className="mb-3"
                        checked={data.active}
                        onChange={a => setData({ active: !data.active })}
                    />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Name</Label>
                                <Validator name="name" type="required">
                                    <Input
                                        type="text"
                                        value={data.name || ''}
                                        onChange={a => setData({
                                            name: a.currentTarget.value,
                                            slug: (data.slug || '') === makeSlug(data.name || '') ? makeSlug(a.currentTarget.value) : data.slug
                                        })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Slug</Label>
                                <Validator name="slug" type="required">
                                    <Input
                                        type="text"
                                        value={data.slug || ''}
                                        onChange={a => setData({slug: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Brand</Label>
                                <Validator name="brand" type="required">
                                    <BrandSelect
                                        value={data.brand}
                                        onChange={a => setData({ brand: a })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Match Configuration</Label>
                                <MatchConfigSelect
                                    value={data.match_config}
                                    onChange={a => setData({ match_config: a })}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Category</Label>
                                <Validator name="category" type="required">
                                    <CategorySelect
                                        value={data.category && data.category.id}
                                        onChange={a => setData({ category: (a && { id: a }) || null })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Product Type</Label>
                                <ProductTypeSelect
                                    value={data.product_type}
                                    onChange={a => setData({ product_type: a })}
                                />
                            </FormGroup>
                        </Col>
                        { data.product_type && <Col className="col-auto align-self-center">
                            <FormGroup>
                                <Label>Properties</Label>
                                <EditPropertyButton
                                    product_type={data.product_type}
                                    value={data.attributes || []}
                                    onChange={a => setData({ attributes: a })}
                                    disabledAttributeIds={childAttributeIds}
                                />
                            </FormGroup>
                        </Col> }
                    </Row>
                </CardBody>
            </Card>
            <CustomTabContainer onAdd={a => setData({ products: (data.products || []).concat([{ main_product: !data.products }]) })}>
                {
                    (data.products || [])
                        .map((a, i) => <CustomTab title={getVariantName(a.variants)} onDelete={() => setData({ products: data.products.filter(b => b !== a)})} key={i}>
                            <ProductEditor
                                value={a}
                                onChange={v => setData({
                                    products: data.products.map(b => b === a ? v : v.main_product ? { ...b, main_product: false } : b)
                                })}
                                productType={realProductType}
                                rootAttributeIds={rootAttributeIds}
                            />
                        </CustomTab>)
                }
            </CustomTabContainer>
        </EntityEditor>
    </Breadcrumb>;
}
