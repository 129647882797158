import React from 'react';
import { FormGroup, Input, Label, ModalBody} from "reactstrap";
import { useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import {FormattedMessage} from "react-intl";
import { ProviderSelect} from "../../Components/Selects";
import {CKEditor} from "react-admin-base-ckeditor";

export default function CommentEntity({url, onReload, id, parent_id}) {
    const entity = useEntity('/comment', id, {parent: parent_id && {id: parent_id}});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Comment Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <CheckBox
                type="checkbox"
                id="active"
                label="Active"
                className="mb-3"
                checked={data.enabled}
                onChange={a => setData({enabled: !data.enabled})}
            />
            <FormGroup>
                <Label htmlFor="text-input">
                   Name
                </Label>
                <Validator
                    name="name"
                    type="required"
                >
                    <Input type="text"
                           value={data.name}
                           onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="USER_MAIL"/></Label>
                <Validator
                    name="email"
                    type="required"
                >
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="text-input"> Star</Label>
                <Validator
                    name="star"
                    type="required"
                >
                    <Input type="text" value={data.star}
                           onChange={a => setData({star: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="MESSAGE"/></Label>
                <CKEditor
                    value={data.message}
                    onChange={a => setData({message: a})}
                />
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}