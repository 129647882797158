import React, {useCallback, useState} from 'react';
import {makeSlug, useAuth} from 'react-admin-base';
import {ApiSelect, Relative} from "react-admin-base-bootstrap";
import BrandEntity from "../pages/Brand/BrandEntity";
import MatchConfigEntity from '../pages/MatchConfig/MatchConfigEntity';
import NetzEntity from '../pages/Netz/NetzEntity';
import ProductTypeEntity from "../pages/ProductType/ProductTypeEntity";
import ProviderEntity from '../pages/Provider/ProviderEntity';
import TarifBonusEntity from '../pages/TarifBonus/TarifBonusEntity';

const removeKey = (k, { [k]:_, ...o }) => o;

export function CreateSelect(props) {
    const { Component, onChange, value } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const onReload = useCallback(function(data) {
        setIsOpen(false);
        data && onChange(data);
    }, [onChange, setIsOpen]);

    return <>
        { isOpen && <Component id={value && value.id} onReload={onReload} /> }
        <ApiSelect {...props} onAddOrEdit={() => setIsOpen(true)} />
    </>;
}

export function BrandSelect(props) {
    return <CreateSelect
        Component={BrandEntity}
        url="/brand"
        {...props}
    />;
}

export function VatSelect(props) {
    return <ApiSelect
        url="/vat"
        {...props}
    />;
}

export function NetzSelect(props) {
    return <CreateSelect
        Component={NetzEntity}
        url="/netz"
        {...props}
    />;
}

export function ProductTypeSelect(props) {
    return <CreateSelect
        Component={ProductTypeEntity}
        url="/product_type"
        {...props}
    />;
}

export function ProviderSelect(props) {
    return <CreateSelect
        Component={ProviderEntity}
        url="/provider"
        {...props}
    />;
}

export function TarifBonusSelect(props) {
    return <CreateSelect
        Component={TarifBonusEntity}
        url="/tarifbonus"
        {...props}
    />;
}

export function MatchConfigSelect(props) {
    return <CreateSelect
        Component={MatchConfigEntity}
        url="/match-config"
        {...props}
    />;
}

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function MapSelect(props) {
    const { value, onChange, staticOptions } = props;

    return <ApiSelect
        {...props}
        staticOptions={Object.entries(staticOptions).map(a => ({ id: +a[0], name: a[1] }))}
        value={value && { id: +value, name: staticOptions[value] }}
        onChange={a => onChange(a && a.id)}
    />;
}

const categoryTypes = {
    1: 'Product',
    2: 'Tarife',
    3: 'Angebote'
};

export function CategoryTypeSelect(props) {
    const { value, onChange } = props;

    console.log('Value:',value, categoryTypes[value]);

    return <ApiSelect
        {...props}
        staticOptions={Object.entries(categoryTypes).map(a => ({ id: +a[0], name: a[1] }))}
        value={value && { id: +value, name: categoryTypes[value] }}
        onChange={a => onChange(a && a.id)}
    />;
}

export function PropertySelect(props) {
    const { product_type_id, selected_ids } = props;

    const group = useCallback(function (rows) {
        return (rows || []).map(row => ({
            label: row.name,
            options: (row.attributes || [])
                .filter(a => selected_ids.indexOf(a.id) === -1)
                .map(a => ({ ...a, attribute_group: row }))
        }));
    }, [selected_ids]);

    return <ApiSelect {...props} group={group} url={"/product_type/" + product_type_id + '/groups?itemPerPage=-1'} />;
}

export function AttributeValueSelect(props) {
    const [ api ] = useAuth();
    const { attribute_id } = props;

    const url = "/attribute/" + attribute_id + "/values?itemPerPage=-1";

    const onCreateOption = useCallback(async function(input) {
        const data = await api.tokenized.put(url, { value: input });
        return data.data;
    }, [api, url]);

    return <ApiSelect
        nameKey="value"
        url={url}
        onCreateOption={onCreateOption}
        {...props}
    />;
}

function BlogSelects(props) {
    const { url } = props;
    const [ loading, setLoading ] = useState(false);
    const [ api ] = useAuth();

    const onCreateOption = useCallback(async function(input) {
        setLoading(true);
        try {
            const data = await api.tokenized.put(url, {
                name: input,
                slug: makeSlug(input)
            });

            return data.data;
        } finally {
            setLoading(false);
        }
    }, [api, url, setLoading]);

    return <ApiSelect
        onCreateOption={onCreateOption}
        isLoading={loading}
        {...props}
    />;
}

export function BlogCategorySelect(props) {
    return <BlogSelects
        url="/blog-category"
        {...props}
    />
}

export function BlogTagSelect(props) {
    return <BlogSelects
        url="/blog-tag"
        {...props}
    />
}

export function UserSelect(props) {
    return <ApiSelect
        url="/user"
        {...props}
    />;
}

export function StatusGroupSelect(props) {
    return <ApiSelect
        url="/status-group?itemPerPage=-1"
        {...props}
    />;
}

export function StatusSelect(props) {
    const group = useCallback(function(row) {
        return row.map(a => ({
            label: a.name,
            options: a.statuses
                .map(b => ({ ...b, status_group: a }))
        }));
    }, []);

    return <ApiSelect
        url="/status-group?itemPerPage=-1"
        group={group}
        {...props}
    />;
}

export function IntegrationSelect(props) {
    const group = useCallback(function(row) {
        return row && row.map(a => removeKey('options', a));
    }, []);

    return <ApiSelect
        url="/integration"
        group={group}
        {...props}
    />;
}
