import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import TarifBonusEntity from "./TarifBonusEntity";

export default function TarifBonus() {
    return <Breadcrumb
        title="Tarif Rabatte"
        data={
            [
                {
                    href: '/tarifbonus',
                    name: 'Tarif Rabatte\n'
                }
            ]
        }
    >
        <CRUD url="/tarifbonus" Component={TarifBonusEntity} add="/tarifbonus/create">
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Name</Column>
                <Column>Ermäßigung</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{(+row.discount).toFixed(2)} &euro;</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/tarifbonus/" + row.id}
                    >
                    </CRUDActions>
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
