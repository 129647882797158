import React from 'react';
import {Button, Col, FormGroup, Input, Label, ModalBody, Row, Table} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import ColorPicker from "../../Components/ColorPicker";
import {MaskButtons, masks} from "./Masks";
import CKEditorModal from '../../Components/CKEditorModal';

export default function StatusGroupEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/status-group', id, { color: { hex: '000000' } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Status Group Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Name</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.name}
                                onChange={a => setData({
                                    name: a.currentTarget.value
                                })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Color</Label>
                        <div>
                            <Validator name="color" type="required">
                                <ColorPicker
                                    value={data.color}
                                    onChange={a => setData({ color: a })}
                                />
                            </Validator>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th className="min">#</th>
                        <th>Name</th>
                        <th>Defaults</th>
                        <th className="min" />
                    </tr>
                </thead>
                <tbody>
                    <MultiValue value={data.statuses} onChange={a => setData({ statuses: a })} stripped>
                        {
                            (row, onChange, index) => <tr key={index}>
                                <td>{index + 1}.</td>
                                <td>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input
                                            type="text"
                                            className="text-center"
                                            value={(row && row.name) || ''}
                                            onChange={a => onChange({ ...(row || {}), name: a.currentTarget.value })}
                                            placeholder="Name"
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Mail Description</Label>
                                        <CKEditorModal
                                            value={(row && row.email_text) || ''}
                                            onChange={a => onChange({ ...(row || {}),  email_text: a })}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <div className="mb-2">
                                        <CheckBox
                                            type="checkbox"
                                            id={"send_email_" + index}
                                            checked={row && row.send_email}
                                            onChange={a => onChange({ ...(row || {}), send_email: !row.send_email })}
                                            label="Send Email"
                                            disabled={!row}
                                        />
                                    </div>
                                    <MaskButtons
                                        prefix={index + "_"}
                                        value={(row && row.type) || 0}
                                        onChange={a => setData({
                                            statuses: data.statuses
                                                .map((b, i) => i === index ? ({ ...b, type: a }) : ({ ...b, type: (b.type || 0) & ~a}))
                                        })}
                                        masks={masks}
                                        disabled={!row}
                                    />
                                </td>
                                <td>
                                    { row && <Button type="button" onClick={a => onChange(null)} size="sm" color="danger" outline>
                                        <i className="fas fa-trash" />
                                    </Button> }
                                </td>
                            </tr>
                        }
                    </MultiValue>
                </tbody>
            </Table>
        </ModalBody>
    </ModalEntityEditor>;
}
