import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import CommentEntity from "./CommentEntity";

export default function Comment() {
    return <Breadcrumb
        title="Comment"
        data={
            [
                {
                    href: '/comment',
                    name: 'Comment'
                }
            ]
        }
    >
        <CRUD url="/comment" Component={CommentEntity} add="/comment/create">
            <thead>
            <tr>
                <IdColumn />
                <Column sort="name">Name</Column>
                <Column sort="nachname">Nachname</Column>
                <Column sort="email">Email</Column>
                <Column sort="phone">Phone</Column>
                <Column sort="created_at">Created at</Column>
                <ActionsColumn />
            </tr>
            </thead>
            <tbody>
            {
                row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.enabled}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{row.star}</td>
                    <td>{row.created_at}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/comment/" + row.id}
                    />
                </tr>
            }
            </tbody>
        </CRUD>
    </Breadcrumb>;
}
