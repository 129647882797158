import React from 'react';
import {Col, FormGroup, Input, Label, ModalBody, Row} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import {CKEditorWithUpload as CKEditor} from 'react-admin-base-ckeditor';
import {CheckBox, ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import SeoEntity from "../../Components/SeoEntity";

export default function PageEntity({ url, onReload, id, parent_id }) {
    const entity = useEntity('/page', id, { parent: parent_id && { id: parent_id } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Page Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <CheckBox
                type="checkbox"
                id="activep"
                label="Active"
                className="mb-3"
                checked={data.enabled || false}
                onChange={a => setData({ enabled: !data.enabled })}
            />
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Name</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.title}
                                onChange={a => setData({
                                    title: a.currentTarget.value,
                                    slug: makeSlug(data.title || '') === (data.slug || '') ? makeSlug(a.currentTarget.value) : data.slug
                                })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Slug</Label>
                        <Validator name="slug" type="required">
                            <Input
                                type="text"
                                value={data.slug}
                                onChange={a => setData({
                                    slug: a.currentTarget.value,
                                })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label>Content</Label>
                <CKEditor
                    value={data.content || ''}
                    onChange={a => setData({
                        content: a
                    })}
                />
            </FormGroup>
            <SeoEntity
                value={data.seo}
                onChange={seo => setData({ seo })}
            />
        </ModalBody>
    </ModalEntityEditor>;
}
