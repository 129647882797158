import React from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {makeSlug, useEntity} from "react-admin-base";
import { CheckBox, EntityEditor, SingleFilePicker, Validator} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {BlogCategorySelect, BlogTagSelect, UserSelect} from "../../Components/Selects";
import {CKEditorWithUpload as CKEditor} from 'react-admin-base-ckeditor';
import SeoEntity from "../../Components/SeoEntity";
import { Navigate, useParams } from 'react-router-dom';
import { WordCounter } from '../../Components/WordCounter';

export default function PostEntity() {
    const { id } = useParams();
    const entity = useEntity('/post', id, { values: { } });
    const [data, setData] = entity;

    return <Breadcrumb
        data={
            [
                {
                    href: '/post',
                    name: 'Post'
                },
                {
                    href: id ? '/post/' + data.id + '/edit' : '/post/create',
                    name: id ? 'Edit' : 'Create'
                }
            ]
        }
    >
        { !id && data.id && <Navigate to={"/post/" + data.id + "/edit"} replace /> }
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <CheckBox
                        type="checkbox"
                        id="active"
                        label="Active"
                        className="mb-3"
                        checked={data.enabled}
                        onChange={a => setData({ enabled: !data.enabled })}
                    />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Name</Label>
                                <Validator name="name" type="required">
                                    <Input
                                        type="text"
                                        value={data.name || ''}
                                        onChange={a => setData({
                                            name: a.currentTarget.value,
                                            slug: (data.slug || '') === makeSlug(data.name || '') ? makeSlug(a.currentTarget.value) : data.slug
                                        })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Slug</Label>
                                <Validator name="slug" type="required">
                                    <Input
                                        type="text"
                                        value={data.slug || ''}
                                        onChange={a => setData({slug: a.currentTarget.value})}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Category</Label>
                                <Validator name="categories" type="required">
                                    <BlogCategorySelect
                                        value={data.categories}
                                        onChange={a => setData({ categories: a })}
                                        isMulti
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Tag</Label>
                                <Validator name="tags" type="required">
                                    <BlogTagSelect
                                        value={data.tags}
                                        onChange={a => setData({ tags: a })}
                                        isMulti
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Author</Label>
                                <Validator name="author" type="required">
                                    <UserSelect
                                        value={data.author}
                                        onChange={a => setData({ author: a })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Published at</Label>
                                <Validator name="published_at" type="required">
                                    <Input
                                        type="datetime-local"
                                        bsSize="lg"
                                        value={data.published_at}
                                        onChange={a => setData({ published_at: a.currentTarget.value })}
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Focus Word</Label>
                        <Input
                            type="text"
                            value={data.focused}
                            onChange={a => setData({ focused: a.currentTarget.value })}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label>Abstract</Label>
                        <Input
                            type="textarea"
                            rows={4}
                            value={data.abstract}
                            onChange={a => setData({ abstract: a.currentTarget.value })}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CKEditor
                    value={data.content}
                    onChange={a => setData({ content: a })}
                />
                <CardBody>
                    <WordCounter
                        focusWord={data.focused}
                        value={data.content}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label>Featured image</Label>
                        <SingleFilePicker
                            accepts="image/*"
                            value={data.featured_image}
                            onChange={a => setData({ featured_image: a })}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <SeoEntity
                        value={data.seo}
                        onChange={seo => setData({ seo })}
                    />
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
}
