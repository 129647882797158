import {useFetch} from 'react-admin-base';

export function customCalculator(priceconfig) {
    let margin = +priceconfig.margin;
    let min_price = +priceconfig.min_price;
    let round = +priceconfig.rounding;
    let tax = 19;

    return function(preis, provision, wkz) {
        var price = ((+preis) - (+provision) - (+wkz || 0) + (+margin)) * ((100+tax)/100);

        if (price <= min_price) {
            price = min_price;
        } else {
            if (round > 0) {
                price = ((round == 1 ? Math.ceil : Math.floor)(price / 5) * 5) - 0.01;
            }
        }

        return price;
    };
}
